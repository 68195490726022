html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
i,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
fieldset,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  /* vertical-align: baseline; */
  font: inherit;
  font-size: 100%;
  -webkit-tap-highlight-color: transparent;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

/**
  * Prevent modern browsers from displaying `audio` without controls.
  * Remove excess height in iOS 5 devices.
  */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
  * Hide the `template` element in IE, Safari, and Firefox < 22.
  */

[hidden],
template {
  display: none;
}

script {
  display: none !important;
}

/* ==========================================================================
    Base
    ========================================================================== */

/**
  * 1. Set default font family to sans-serif.
  * 2. Prevent iOS text size adjust after orientation change, without disabling
  *  user zoom.
  */

html {
  font-family: sans-serif; /* 1 */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/**
  * Remove default margin.
  * '宋体'针对UC浏览器字体显示过大
  */

body {
  margin: 0;
  line-height: 1;
  font-family: '-apple-system', 'Helvetica Neue', 'Roboto', 'Segoe UI', tahoma, arial,
    Hiragino Sans GB, sans-serif, '宋体';
  background-color: transparent !important;
}
html {
  background-color: transparent;
}

/**
  * Remove default outlines.
  */
a,
button,
:focus,
a:focus,
button:focus,
a:active,
a:hover {
  outline: 0;
}

/* *
  * Remove tap highlight color
  */

a {
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
}
a[href]:hover {
  cursor: pointer;
}
/* ==========================================================================
    Typography
    ========================================================================== */

/**
  * Address style set to `bolder` in Firefox 4+, Safari 5, and Chrome.
  */

b,
strong {
  font-weight: bold;
}

/**
  * Address styling not present in Safari 5 and Chrome.
  */

dfn {
  font-style: italic;
}

/**
  * Address differences between Firefox and other browsers.
  */

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

/**
  * Correct font family set oddly in Safari 5 and Chrome.
  */

code,
kbd,
pre,
samp {
  font-size: 1em;
  font-family: monospace, serif;
}

/**
  * Improve readability of pre-formatted text in all browsers.
  */

pre {
  white-space: pre-wrap;
}

/**
  * Set consistent quote types.
  */

q {
  quotes: '\201C''\201D''\2018''\2019';
}

/**
  * Address inconsistent and variable font size in all browsers.
  */

small {
  font-size: 80%;
}

/**
  * Prevent `sub` and `sup` affecting `line-height` in all browsers.
  */

sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/**
  * Define consistent border, margin, and padding.
  */

fieldset {
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
  border: 1px solid #c0c0c0;
}

/**
  * 1. Correct `color` not being inherited in IE 8/9.
  * 2. Remove padding so people aren't caught out if they zero out fieldsets.
  */

legend {
  padding: 0; /* 2 */
  border: 0; /* 1 */
}

/**
  * 1. Correct font family not being inherited in all browsers.
  * 2. Correct font size not being inherited in all browsers.
  * 3. Address margins set differently in Firefox 4+, Safari 5, and Chrome.
  * 4. Remove any default :focus styles
  * 5. Make sure webkit font smoothing is being inherited
  * 6. Remove default gradient in Android Firefox / FirefoxOS
  */

button,
input,
select,
textarea {
  margin: 0; /* 3 */
  font-size: 100%; /* 2 */
  font-family: inherit; /* 1 */
  outline-offset: 0; /* 4 */
  outline-style: none; /* 4 */
  outline-width: 0; /* 4 */
  -webkit-font-smoothing: inherit; /* 5 */
  background-image: none; /* 6 */
}

/**
  * Address Firefox 4+ setting `line-height` on `input` using `importnt` in
  * the UA stylesheet.
  */

button,
input {
  line-height: normal;
}

/**
  * Address inconsistent `text-transform` inheritance for `button` and `select`.
  * All other form control elements do not inherit `text-transform` values.
  * Correct `button` style inheritance in Chrome, Safari 5+, and IE 8+.
  * Correct `select` style inheritance in Firefox 4+ and Opera.
  */

button,
select {
  text-transform: none;
}

/**
  * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
  *  and `video` controls.
  * 2. Correct inability to style clickable `input` types in iOS.
  * 3. Improve usability and consistency of cursor style between image-type
  *  `input` and others.
  */

button,
 html input[type="button"], /* 1 */
 input[type="reset"],
 input[type="submit"] {
  cursor: pointer; /* 3 */
  -webkit-appearance: button; /* 2 */
}

/**
  * Re-set default cursor for disabled elements.
  */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
  * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
  * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
  *  (include `-moz` to future-proof).
  */

input[type='search'] {
  -webkit-box-sizing: content-box; /* 2 */
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield; /* 1 */
}

/**
  * Remove inner padding and search cancel button in Safari 5 and Chrome
  * on OS X.
  */

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
  * Remove inner padding and border in Firefox 4+.
  */

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

/**
  * 1. Remove default vertical scrollbar in IE 8/9.
  * 2. Improve readability and alignment in all browsers.
  */

textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

img {
  -webkit-user-drag: none;
}

/* ==========================================================================
    Tables
    ========================================================================== */

/**
  * Remove most spacing between table cells.
  */

table {
  border-spacing: 0;
  border-collapse: collapse;
}
